import { Link } from "@StarberryUtils"
import React, { useState } from "react"
// import BannerImgDesk from "../../../images/PropertyDetails/banner-bg-desktop.jpg"
// import BannerImgTab from "../../../images/PropertyDetails/banner-bg-tab.jpg"
// import BannerImgMob from "../../../images/PropertyDetails/banner-bg.jpg"
import { IconZoom } from "../../icons"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./PropertyDetailsBanner.scss"
import PlayVideo from "../../Play/PlayVideo"
import ImageTransform from "../../Common/ggfx-client/module/components/image-transform"
import BookaViewForm from "../../../Components/ValuationSteps/BookaViewComponent"
import $ from "jquery"
import getVideoId from "get-video-id"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  delayItemsFromBottom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import Lightbox from "react-image-lightbox"
import { navigate } from "@reach/router"
import "react-image-lightbox/style.css"

import {
  ScrollingProvider,
  useScrollSections,
  useScrollSection,
  Section,
} from "react-scroll-section"
const PropertyBanner = props => {
  const detailSection = useScrollSection("detail")
  const [indexed, setIndex] = useState(1)
  const afterChangeHandler = d => {
    setIndex(d + 1)
  }

  // Banner lightbox
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const openLighboxMobile = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }

  var lightImages2 =
    props.property_images && props.property_images.map(img => img.url)
  // Banner lightbox

  const [Bookaviewform, setBookaviewform] = useState(false)

  const [isPlay, setPlay] = useState(false)

  //
  const slideClick = value => {
    props.handleClick(value)
  }
  //

  //
  const bookClick = value => {
    props.handleBookClick(value)
  }
  //

  // History back
  const handleBack = () => {
    // window?.history?.previous?.href()
    var propurl = sessionStorage.getItem("propertyPath")

    if (propurl === null) {
      if (props?.property_type == "sales") {
        propurl =
          "/property/for-sale/in-stoke-newington-and-hackney-and-north-london-and-east-london"
        navigate(propurl)
      }
      if (props?.property_type == "lettings") {
        propurl =
          "/property/to-rent/in-stoke-newington-and-hackney-and-north-london-and-east-london"
        navigate(propurl)
      }
    } else {
      navigate(propurl)
    }
  }
  // History back

  var listname = ""

  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.images_Transforms) {
    processedImages = props.imagetransforms.images_Transforms
  }
  var videoTour = props.property_video && props.property_video[0]
  var videoTourID = ""
  if (videoTour) {
    videoTourID = getVideoId(videoTour)
  }
  const viewReport = sessionStorage.getItem("spriftlink")
  const reportResult = viewReport?.startsWith("https://sprift.com/");
  return (
    <React.Fragment>
      {!Bookaviewform && (
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.section
              className="property-details-banner"
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
            >
              <div className="property-banner-wrapper">
                {props.property_images && props.property_images?.length > 0 ? (
                  <ImageTransform
                    imagesources={props.property_images[0].url}
                    renderer="srcSet"
                    imagename="property.images.detail"
                    attr={{
                      alt:
                        props.property_display_address + " - Location Location",
                    }}
                    imagetransformresult={processedImages}
                    id={props.id}
                    testparam={true}
                  />
                ) :
                  (
                    <div className="noImageProperty detail-page">
                      <p class="text"><i className="icon-noimage"></i> image coming soon</p>
                    </div>
                  )
                }

                <div className="details-overlay-bg"></div>
              </div>
              {props.property_images && props.property_images?.length > 0 && (
                <>
                  {/* <div className="property-tags d-flex align-items-center justify-content-center">
                        <span
                          className="full-view"
                          onClick={e => openLighboxMobile(e, 0)}
                        >
                          <IconZoom />
                        </span>
                        <span
                          className="slide-count enlarge-view-prop pl-3"
                          onClick={e => openLighboxMobile(e, 0)}
                        >
                          {indexed}/{props.property_images.length} Photos
                        </span>
                      </div> */}
                  {isOpen && (
                    <Lightbox
                      mainSrc={lightImages2[photoIndex]}
                      nextSrc={
                        lightImages2[(photoIndex + 1) % lightImages2.length]
                      }
                      prevSrc={
                        lightImages2[
                        (photoIndex + lightImages2.length - 1) %
                        lightImages2.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + lightImages2.length - 1) %
                          lightImages2.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % lightImages2.length)
                      }
                    />
                  )}
                </>
              )}
              <div className="property-banner-content property-banner">
                <Container>
                  {videoTourID && videoTourID.id && (
                    <a
                      href="javascript:;"
                      className="banner-icon"
                      onClick={e => {
                        setPlay(true)
                      }}
                    >
                      <i className="icon-video-white"></i>
                    </a>
                  )}
                  <a
                    // to={propurl}
                    href="javascript:void(0)"
                    onClick={handleBack}
                    className="back-to d-none d-xl-inline-flex"
                  >
                    <i className="icon-angle-left d-none d-xl-block"></i> Back
                    to search results
                  </a>

                  {props.property_display_address && (
                    <motion.h1 variants={contentItem}>
                      {/* <Link to={propurl} onClick={handleBack}>
                        <i className="icon-angle-left d-none d-xl-block"></i>
                      </Link> */}
                      {props.property_display_address}
                    </motion.h1>
                  )}
                  <div className="property-details-items">
                    {props.property_price > 0 && (
                      <motion.p variants={contentItem} className="price-name">
                        £{props.property_price.toLocaleString()}
                        <span className="price-qualifier">{props?.price_qualifier && props.price_qualifier}</span>
                        {props.property_type == "lettings" ? " p/m" : ""}
                      </motion.p>
                    )}
                    {props.status.trim().toLowerCase().replace(/ /g, "_") === "under_offer" && (
                      <motion.div className="under-offer-content">
                        <motion.p
                          variants={contentItem}
                          className="under-offer"
                        >
                          Under Offer
                        </motion.p>
                      </motion.div>
                    )}
                    {props.status.trim().toLowerCase().replace(/ /g, "_") === "let_agreed" && (
                      <motion.div className="under-offer-content">
                        <motion.p
                          variants={contentItem}
                          className="under-offer"
                        >
                          Let Agreed
                        </motion.p>
                      </motion.div>
                    )}
                  </div>
                  <div className="banner-list-content">
                    <motion.ul variants={contentItem}>
                      {props.property_bedroom > 0 && (
                        <li className="bedroom">
                          <i className="icon-bed"></i>
                          <p>
                            {props.property_bedroom > 0
                              ? props.property_bedroom + " Bedrooms"
                              : props.property_bedroom + " Bedroom"}
                          </p>
                        </li>
                      )}
                      {props.property_bathroom > 0 && (
                        <li className="Bathrooms">
                          <i className="icon-bathroom"></i>
                          <p>
                            {props.property_bathroom > 0
                              ? props.property_bathroom + " Bathrooms"
                              : props.property_bathroom + " Bathroom"}
                          </p>
                        </li>
                      )}
                      {props.property_reception > 0 && (
                        <li className="reception">
                          <i className="icon-reception"></i>
                          <p>
                            {props.property_reception > 0
                              ? props.property_reception + " Receptions"
                              : props.property_reception + " Reception"}
                          </p>
                        </li>
                      )}
                      {props.property_images && (
                        <li
                          className="img-gal-icon"
                          onClick={e => openLighboxMobile(e, 0)}
                        >
                          {/* <IconZoom /> */}
                          <i className="icon-view-img"></i>
                          <p>
                            {/* {indexed}/ */}
                            {props.property_images.length} Photos
                          </p>
                        </li>
                      )}
                      {/*
            <li className="garden">
              <i className="icon-garden"></i>
              <p>Private Garden</p>
            </li>
            <li className="chainfree">
              <i className="icon-chainfree"></i>
              <p>Chain Free</p>
            </li>
            */}
                    </motion.ul>
                  </div>
                  <div className="d-none d-xl-block">
                    <motion.div
                      variants={contentItem}
                      className="prop-banner-btn-group "
                    >
                      <a
                        href="javascript:;"
                        onClick={() => bookClick("BookaViewing")}
                        className="btn btn-yellow"
                      >
                        Request a Viewing
                      </a>
                      <a
                        onClick={detailSection.onClick}
                        href="javascript:;"
                        className="btn btn-outline"
                      >
                        More Information
                      </a>
                      {/* {reportResult == true &&  (
                      <a 
                      className="btn btn-outline" 
                      href={viewReport}
                      target='_blank'
                      >
                      {props.property_type === "sales"
                          ? "Buyers Report"
                          : "Residents Report"}
                      </a>
                      )
                      } */}
                      {props.property_brochure && props.property_brochure?.length > 0 ?
                        <a
                          className="btn btn-outline"
                          href={props.property_brochure[0].media_url}
                          target='_blank'
                        >
                          Buyers Report
                        </a>
                        :<Link to={`/property-buyers-report/`} className="btn btn-outline">Buyers Report</Link>

                      }

                    </motion.div>
                  </div>
                </Container>
              </div>
              {videoTourID && videoTourID.id && (
                <PlayVideo
                  isOpen={isPlay}
                  isCloseFunction={setPlay}
                  videoId={videoTour}
                  isAutoPlay={1}
                />
              )}
            </motion.section>
          )}
        </InView>
      )}
      {Bookaviewform && <BookaViewForm Bgimg={props.property_images[0].url}
        property_area={props?.area}

      />}
    </React.Fragment>
  )
}

export default PropertyBanner